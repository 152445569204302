<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 8 8" fill="none">
    <path
      d="M1 4.75024L3.1816 7C4.2088 4.05088 5.0632 2.7568 7 1"
      stroke="currentColor"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
